import React from 'react';
import TimelineItem from './TimelineItem';
import ExpandableSection from './ExpandableSection';

const translations = {
  pl: {
    title: "Edukacja i Praca",
    entries: [
      {
        date: "2017-2020",
        institution: "Zespół Szkół nr 1 w Bochni",
        description: "Technik Informatyk - E12, E13, E14\nŚwiadectwo ukończenia szkoły z wyróżnieniem",
        logoSrc: "./school-logo.png",
      },
      {
        date: "2020-2024",
        institution: "Politechnika Krakowska",
        description: "Kierunek: Informatyka\nUkończono z oceną 5.0 wraz z wyróżnieniem",
        logoSrc: "./university-logo.png",
      },
      {
        date: "2022 lipiec - 2022 wrzesień",
        institution: "CAE",
        description: "Intern Software Engineer\nTechnologie: Java 8, Spring Framework, Hibernate, JUnit, Groovy, Spock",
        logoSrc: "./cae-logo.png",
      },
      {
        date: "2022 październik - obecnie",
        institution: "CAE",
        description: "Associate Software Engineer\nTechnologie: Java 8 & 11, Spring Framework, Spring Boot, Hibernate, JUnit, Groovy, Spock, AWS, Docker, Gradle, Maven, JMS",
        logoSrc: "./cae-logo.png",
      }
    ]
  },
  en: {
    title: "Education and Work",
    entries: [
      {
        date: "2017-2020",
        institution: "Zespół Szkół nr 1 w Bochnia",
        description: "IT Technician - E12, E13, E14\nDiploma with distinction",
        logoSrc: "./school-logo.png",
      },
      {
        date: "2020-2024",
        institution: "Cracow University of Technology",
        description: "Engineer: Computer Science\nGraduated with a 5.0 GPA and honors",
        logoSrc: "./university-logo.png",
      },
      {
        date: "July 2022 - September 2022",
        institution: "CAE",
        description: "Intern Software Engineer\nTechnologies: Java 8, Spring Framework, Hibernate, JUnit, Groovy, Spock",
        logoSrc: "./cae-logo.png",
      },
      {
        date: "October 2022 - Present",
        institution: "CAE",
        description: "Associate Software Engineer\nTechnologies: Java 8 & 11, Spring Framework, Spring Boot, Hibernate, JUnit, Groovy, Spock, AWS, Docker, Gradle, Maven, JMS",
        logoSrc: "./cae-logo.png",
      }
    ]
  }
};

const EducationAndWork = ({ language }) => {
  const currentTranslation = translations[language];

  return (
    <ExpandableSection title={currentTranslation.title}>
      {currentTranslation.entries.map((entry, index) => (
        <TimelineItem
          key={index}
          date={entry.date}
          institution={entry.institution}
          description={entry.description}
          logoSrc={entry.logoSrc}
        />
      ))}
    </ExpandableSection>
  );
};

export default EducationAndWork;

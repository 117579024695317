import React from 'react';
import { Box, Typography, Link } from '@mui/material';

const CertificateItem = ({ logoSrc, altText, title, issueLabel, expiryLabel, issueDate, expiryDate, link, linkLabel }) => {
  return (
    <Box display="flex" alignItems="center" mb={2}>
      <Box sx={{ width: '100px', height: '100px', mr: 2 }}>
        <img
          src={logoSrc}
          alt={altText}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'contain',
          }}
        />
      </Box>

      <Box>
        <Typography variant="h6" color="textSecondary">
          {title}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {issueLabel}: {issueDate}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {expiryLabel}: {expiryDate}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          <Link href={link} target="_blank" color="primary">
            {linkLabel}
          </Link>
        </Typography>
      </Box>
    </Box>
  );
};

export default CertificateItem;

import React from 'react';
import CertificateItem from './CertificateItem';
import ExpandableSection from './ExpandableSection';

const translations = {
  pl: {
    title: 'Certyfikaty',
    certificates: [
      {
        logoSrc: './aws.png',
        altText: 'AWS Logo',
        title: 'AWS Certified Cloud Practitioner',
        issueDate: 'maj 2024',
        expiryDate: 'maj 2027',
        link: 'https://www.credly.com/badges/f4469443-5493-4968-a731-84f24106b76d/linked_in_profile',
      },
    ],
    issueLabel: 'Wydano',
    expiryLabel: 'Wygasa',
    linkLabel: 'Zobacz certyfikat',
  },
  en: {
    title: 'Certificates',
    certificates: [
      {
        logoSrc: './aws.png',
        altText: 'AWS Logo',
        title: 'AWS Certified Cloud Practitioner',
        issueDate: 'May 2024',
        expiryDate: 'May 2027',
        link: 'https://www.credly.com/badges/f4469443-5493-4968-a731-84f24106b76d/linked_in_profile',
      },
    ],
    issueLabel: 'Issued',
    expiryLabel: 'Expires',
    linkLabel: 'View certificate',
  },
};

const Certificates = ({ language }) => {
  const currentTranslation = translations[language];

  return (
    <ExpandableSection title={currentTranslation.title}>
      {currentTranslation.certificates.map((certificate, index) => (
        <CertificateItem
          key={index}
          logoSrc={certificate.logoSrc}
          altText={certificate.altText}
          title={certificate.title}
          issueDate={certificate.issueDate}
          expiryDate={certificate.expiryDate}
          link={certificate.link}
          issueLabel={currentTranslation.issueLabel}
          expiryLabel={currentTranslation.expiryLabel}
          linkLabel={currentTranslation.linkLabel}
        />
      ))}
    </ExpandableSection>
  );
};

export default Certificates;

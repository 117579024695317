// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml,<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 1000 100\" fill=\"%236A1B9A\"><path d=\"M0 1v99c134.3 0 153.7-99 296-99H0Z\" opacity=\".5\"></path><path d=\"M1000 4v86C833.3 90 833.3 3.6 666.7 3.6S500 90 333.3 90 166.7 4 0 4h1000Z\" opacity=\".5\"></path><path d=\"M617 1v86C372 119 384 1 196 1h421Z\" opacity=\".5\"></path><path d=\"M1000 0H0v52C62.5 28 125 4 250 4c250 0 250 96 500 96 125 0 187.5-24 250-48V0Z\"></path></svg>", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  font-family: 'Tomorrow', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #d1d1d1;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}

* {
  font-family: 'Tomorrow', sans-serif !important;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: #f0f0f0;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #6a1b9a;
  border-radius: 12px;
  border: 3px solid #f0f0f0;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #53157d;
}

html {
  scrollbar-color: #6a1b9a #f0f0f0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,mCAAmC;EACnC,SAAS;EACT,UAAU;EACV,yBAAyB;EACzB,yDAA2c;EAC3c,4BAA4B;EAC5B,sBAAsB;EACtB,wBAAwB;AAC1B;;AAEA;EACE,8CAA8C;AAChD;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE;aACW;AACb","sourcesContent":["body {\n  font-family: 'Tomorrow', sans-serif;\n  margin: 0;\n  padding: 0;\n  background-color: #d1d1d1;\n  background-image: url('data:image/svg+xml,<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 1000 100\" fill=\"%236A1B9A\"><path d=\"M0 1v99c134.3 0 153.7-99 296-99H0Z\" opacity=\".5\"></path><path d=\"M1000 4v86C833.3 90 833.3 3.6 666.7 3.6S500 90 333.3 90 166.7 4 0 4h1000Z\" opacity=\".5\"></path><path d=\"M617 1v86C372 119 384 1 196 1h421Z\" opacity=\".5\"></path><path d=\"M1000 0H0v52C62.5 28 125 4 250 4c250 0 250 96 500 96 125 0 187.5-24 250-48V0Z\"></path></svg>');\n  background-repeat: no-repeat;\n  background-size: cover;\n  background-position: top;\n}\n\n* {\n  font-family: 'Tomorrow', sans-serif !important;\n}\n\n::-webkit-scrollbar {\n  width: 12px;\n}\n\n::-webkit-scrollbar-track {\n  background: #f0f0f0;\n  border-radius: 10px;\n}\n\n::-webkit-scrollbar-thumb {\n  background-color: #6a1b9a;\n  border-radius: 12px;\n  border: 3px solid #f0f0f0;\n}\n\n::-webkit-scrollbar-thumb:hover {\n  background-color: #53157d;\n}\n\nhtml {\n  scrollbar-color: #6a1b9a #f0f0f0;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

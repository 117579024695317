import React from 'react';
import { Container, Typography, Avatar, Box, IconButton } from '@mui/material';
import PolishFlag from './polish-flag.svg';
import BritishFlag from './british-flag.svg';

const Header = ({ onLanguageChange }) => {

  return (
    <Container
      maxWidth="lg"
      style={{
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginTop: '20px',
        borderRadius: '16px',
        boxShadow: '0px 0px 8px 4px rgba(0, 0, 0, 0.35)',
      }}
    >
      <Box display="flex" flexDirection="column" alignItems="center" mb={2}>
        <Avatar
          style={{
            border: '1px solid #6a1b9a',
            boxShadow: '0px 0px 8px 4px rgba(0, 0, 0, 0.35)',
          }}
          src='./profile2.png'
          sx={{ width: 200, height: 200, mb: 2 }}
        />
        <Typography
          variant="h4"
          component="h1"
          color="primary"
          sx={{ fontWeight: 'bold', textAlign: 'center', userSelect: 'none' }}
        >
          Filip Mróz
        </Typography>

        <Box display="flex" justifyContent="center" mt={2}>
          <IconButton
            onClick={() => onLanguageChange('pl')}
            sx={{
              width: 40,
              height: 20,
              mx: 1,
              padding: 0,
            }}
          >
            <img
              src={PolishFlag}
              alt="Polish Flag"
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                cursor: 'pointer',
                border: '1px solid black',
              }}
            />
          </IconButton>

          <IconButton
            onClick={() => onLanguageChange('en')}
            sx={{
              width: 40,
              height: 20,
              mx: 1,
              padding: 0,
            }}
          >
            <img
              src={BritishFlag}
              alt="British Flag"
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                cursor: 'pointer',
                border: '1px solid black',
              }}
            />
          </IconButton>
        </Box>

      </Box>
    </Container>
  );
};

export default Header;

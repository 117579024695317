import React from 'react';
import ExpandableSection from './ExpandableSection';

const translations = {
  pl: {
    aboutText: `
    Cześć! Mam na imię Filip i urodziłem się w 2000 roku. Już od najmłodszych lat fascynowała mnie informatyka, co z czasem przerodziło się w moją życiową pasję. W gimnazjum miałem okazję brać udział w pierwszych konkursach informatycznych, co tylko utwierdziło mnie w przekonaniu, że jest to dziedzina, w której chcę się rozwijać.\n

    Moja dalsza edukacja odbywała się w Zespole Szkół nr 1 w Bochni, gdzie w latach 2017-2020 uczęszczałem na kierunku technik informatyk. W trakcie tych lat zdobyłem solidne podstawy z zakresu technologii informacyjnych, uzyskując kwalifikacje E12, E13 oraz E14. Te kwalifikacje obejmują montaż i eksploatację komputerów osobistych, projektowanie i administrowanie lokalnymi sieciami komputerowymi, a także tworzenie aplikacji internetowych oraz baz danych. Dzięki temu ukończyłem szkołę z dyplomem technika informatyka, co dało mi solidne fundamenty do dalszego rozwoju.\n

    W 2020 roku rozpocząłem studia na Politechnice Krakowskiej na kierunku Informatyka. Przez cztery lata zgłębiałem różne aspekty tej dziedziny, od programowania po metodyki zarządzania projektami IT. Tematem mojej pracy inżynierskiej była "Aplikacja do zarządzania projektami IT w metodykach zwinnych", którą obroniłem z najwyższą oceną – 5.0, kończąc studia z wyróżnieniem.\n

    W 2022 roku miałem przyjemność rozpocząć staż jako software engineer w firmie CAE. Trzy miesiące pracy w tej firmie pozwoliły mi zdobyć cenne doświadczenie i praktyczną wiedzę na temat wytwarzania oprogramowania, co otworzyło mi drzwi do dalszej kariery w tym zespole, w którym pracuję do dzisiaj.\n

    Poza pracą zawodową nieustannie rozwijam swoje umiejętności. Tworzę własne projekty, testując nowe technologie i narzędzia. W wolnych chwilach pracuję na swoim Raspberry Pi, na które wrzucam różne projekty, którymi dzielę się z przyjaciółmi. To dla mnie nie tylko forma rozwoju, ale także świetna zabawa.\n

    Oprócz informatyki, jestem wielkim fanem motorsportu, a szczególnie Formuły 1, którą pasjonuję się od lat. Uwielbiam śledzić wyścigi i zgłębiać tajniki tej fascynującej dyscypliny.\n
    `,
  },
  en: {
    aboutText: `
    Hi! My name is Filip, and I was born in 2000. Since childhood, I have been fascinated by computer science, which eventually became my life's passion. In middle school, I had the opportunity to participate in my first IT competitions, which only confirmed my belief that this is the field I want to pursue.\n

    I completed my secondary education at the Zespół Szkół nr 1 in Bochnia, where from 2017 to 2020 I studied as an IT technician. During these years, I gained a solid foundation in IT technologies, obtaining E12, E13, and E14 qualifications. These qualifications cover the assembly and maintenance of personal computers, designing and administering local networks, as well as creating web applications and databases. Thanks to this, I graduated with a diploma in IT, which gave me a strong foundation for further development.\n

    In 2020, I started studying at the Cracow University of Technology in Computer Science. For four years, I delved into various aspects of this field, from programming to IT project management methodologies. The topic of my engineering thesis was "An Application for IT Project Management in Agile Methodologies," which I defended with the highest grade – 5.0, graduating with honors.\n

    In 2022, I had the pleasure of starting an internship as a software engineer at CAE. Three months of work at this company allowed me to gain valuable experience and practical knowledge about software development, which opened doors to further my career in this team, where I still work today.\n

    Besides my professional work, I constantly develop my skills. I create my own projects, testing new technologies and tools. In my spare time, I work on my Raspberry Pi, on which I deploy various projects that I share with my friends. It's not only a form of development but also a lot of fun for me.\n

    Apart from IT, I'm a big motorsport fan, especially Formula 1, which I've been passionate about for years. I love following the races and exploring the secrets of this fascinating sport.\n
    `,
  },
};

const renderWithLineBreaks = (text) => {
  return text.split('\n').map((str, index) => (
    <React.Fragment key={index}>
      <span style={{ display: 'block', lineHeight: '1.4', marginBottom: '10px', textAlign: 'justify' }}>{str}</span>
    </React.Fragment>
  ));
};

const About = ({ language }) => {
  const currentTranslation = translations[language];

  return (
    <ExpandableSection title={language === 'pl' ? 'O mnie' : 'About Me'} defaultExpanded={true}>
      {renderWithLineBreaks(currentTranslation.aboutText)}
    </ExpandableSection>
  );
};

export default About;

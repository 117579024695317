import React from 'react';
import SkillItem from './SkillItem';
import ExpandableSection from './ExpandableSection';

const translations = {
    pl: {
        title: 'Inne rozwijane umiejętności prywatnie',
    },
    en: {
        title: 'Other Skills Developed Privately',
    },
};

const PrivateSkills = ({language}) => {
    const currentTranslation = translations[language];

    return (
        <ExpandableSection title={currentTranslation.title} isGrid={true}>
            <SkillItem
                logoSrc="./java.png"
                altText="Java Logo"
                skillName="Java 17 & 21"
            />
            <SkillItem
                logoSrc="./dart.png"
                altText="Dart Logo"
                skillName="Dart"
            />
            <SkillItem
                logoSrc="./flutter.png"
                altText="Flutter Logo"
                skillName="Flutter"
            />
            <SkillItem
                logoSrc="./js.svg"
                altText="JavaScript Logo"
                skillName="JavaScript"
            />
            <SkillItem
                logoSrc="./ts.png"
                altText="TypeScript Logo"
                skillName="TypeScript"
            />
            <SkillItem
                logoSrc="./react.png"
                altText="React Logo"
                skillName="React"
            />
            <SkillItem
                logoSrc="./jwt.svg"
                altText="JWT Logo"
                skillName="JWT"
            />
            <SkillItem
                logoSrc="./restapi.png"
                altText="Rest API"
                skillName="Rest API"
            />
            <SkillItem
                logoSrc="./tomcat.png"
                altText="Tomcat Logo"
                skillName="Tomcat"
            />
            <SkillItem
                logoSrc="./postgres.png"
                altText="PostgreSQL Logo"
                skillName="PostgreSQL"
            />
        </ExpandableSection>
    );
};

export default PrivateSkills;

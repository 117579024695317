import React, { useState, useRef, useEffect } from 'react';
import { Container, Typography, Box } from '@mui/material';

const ExpandableSection = ({ title, children, defaultExpanded = false, isGrid = false }) => {
    const [isExpanded, setIsExpanded] = useState(defaultExpanded);
    const [contentHeight, setContentHeight] = useState('0px');
    const contentRef = useRef(null);

    const toggleExpanded = () => {
        setIsExpanded(!isExpanded);
    };

    useEffect(() => {
        if (isExpanded) {
            setContentHeight(`${contentRef.current.scrollHeight}px`);
        } else {
            setContentHeight('0px');
        }
    }, [isExpanded]);

    useEffect(() => {
        if (defaultExpanded && contentRef.current) {
            setContentHeight(`${contentRef.current.scrollHeight}px`);
        }
    }, [defaultExpanded]);

    return (
        <Container
            maxWidth="lg"
            style={{
                backgroundColor: '#fff',
                padding: '20px',
                marginTop: '20px',
                borderRadius: '16px',
                boxShadow: '0px 0px 8px 4px rgba(0, 0, 0, 0.35)',
            }}
        >
            <Typography
                variant="h5"
                component="h2"
                color="primary"
                gutterBottom
                sx={{ fontWeight: 'bold', textAlign: 'center', cursor: 'pointer', userSelect: 'none', }}
                onClick={toggleExpanded}
            >
                {title}
            </Typography>

            <Box
                ref={contentRef}
                sx={{
                    maxHeight: contentHeight,
                    overflow: 'hidden',
                    transition: 'max-height 0.5s ease',
                    display: isGrid ? 'flex' : 'block',
                    flexWrap: isGrid ? 'wrap' : 'initial',
                    justifyContent: isGrid ? 'space-between' : 'initial',
                    gap: isGrid ? '20px' : '0',
                }}
            >
                {children}
            </Box>
        </Container>
    );
};

export default ExpandableSection;

import React from 'react';
import { Box, Typography } from '@mui/material';

const SkillItem = ({ logoSrc, altText, skillName }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      mb={2}
      sx={{
        flexBasis: 'calc(33.333% - 20px)',
        flexGrow: 1,
        marginTop: '10px',
      }}
    >
      <Box sx={{ width: '60px', height: '60px', mr: 2 }}>
        <img
          src={logoSrc}
          alt={altText}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'contain',
          }}
        />
      </Box>

      <Typography variant="h6" color="textSecondary">
        {skillName}
      </Typography>
    </Box>
  );
};

export default SkillItem;

import React, { useState } from 'react';
import { Container } from '@mui/material';
import Header from './Header';
import About from './About';
import EducationAndWork from './EducationAndWork';
import Certificates from './Certificates';
import CommercialSkills from './CommercialSkills';
import PrivateSkills from './PrivateSkills';
import Contact from './Contact';

const App = () => {
  const [language, setLanguage] = useState('pl');

  const handleLanguageChange = (lang) => {
    setLanguage(lang);
  };

  return (
    <Container style={{ paddingBottom: '20px' }}>
      <Header language={language} onLanguageChange={handleLanguageChange} />
      <About language={language} />
      <EducationAndWork language={language} />
      <Certificates language={language} />
      <CommercialSkills language={language} />
      <PrivateSkills language={language} />
      <Contact language={language} />
    </Container>
  );
};

export default App;

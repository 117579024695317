import React from 'react';
import SkillItem from './SkillItem';
import ExpandableSection from './ExpandableSection';

const translations = {
  pl: {
    title: 'Umiejętności wykorzystywane komercyjnie',
  },
  en: {
    title: 'Commercial Skills',
  },
};

const CommercialSkills = ({ language }) => {
  const currentTranslation = translations[language];

  return (
    <ExpandableSection title={currentTranslation.title} isGrid={true}>
      <SkillItem
        logoSrc="./java.png"
        altText="Java Logo"
        skillName="Java 8 & 11"
      />
      <SkillItem
        logoSrc="./spring.png"
        altText="Spring Logo"
        skillName="Spring Framework"
      />
      <SkillItem
        logoSrc="./hibernate.png"
        altText="Hibernate Logo"
        skillName="Hibernate"
      />
      <SkillItem
        logoSrc="./junit.png"
        altText="JUnit 5 Logo"
        skillName="JUnit 5"
      />
      <SkillItem
        logoSrc="./groovy.png"
        altText="Groovy Logo"
        skillName="Groovy"
      />
      <SkillItem
        logoSrc="./spock.png"
        altText="Spock Logo"
        skillName="Spock"
      />
      <SkillItem
        logoSrc="./aws.png"
        altText="AWS Logo"
        skillName="AWS"
      />
      <SkillItem
        logoSrc="./docker.png"
        altText="Docker Logo"
        skillName="Docker"
      />
      <SkillItem
        logoSrc="./gradle.png"
        altText="Gradle Logo"
        skillName="Gradle"
      />
      <SkillItem
        logoSrc="./maven.svg"
        altText="Maven Logo"
        skillName="Maven"
      />
      <SkillItem
        logoSrc="./jms.jpg"
        altText="JMS"
        skillName="JMS"
      />
      <SkillItem
        logoSrc="./kafka.jpg"
        altText="Kafka logo"
        skillName="Kafka"
      />
      <SkillItem
        logoSrc="./oracledb.png"
        altText="Oracle DB Logo"
        skillName="Oracle DB"
      />
    </ExpandableSection>
  );
};

export default CommercialSkills;

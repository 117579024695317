import React from 'react';
import { Box, Typography } from '@mui/material';

const renderWithLineBreaks = (text) => {
  return text.split('\n').map((str, index) => (
    <React.Fragment key={index}>
      {str}
      <br />
    </React.Fragment>
  ));
};

const TimelineItem = ({ logoSrc, date, institution, description }) => {
  return (
    <Box display="flex" alignItems="center" mb={2}>
      <Box sx={{ width: '100px', height: '100px', mr: 2 }}>
        <img
          src={logoSrc}
          alt={`${institution} logo`}
          style={{
            width: '100px',
            height: '100px',
            objectFit: 'contain',
          }}
        />
      </Box>

      <Box>
        <Typography variant="h6" color="textPrimary">
          {date}: {institution}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {renderWithLineBreaks(description)}
        </Typography>
      </Box>
    </Box>
  );
};

export default TimelineItem;

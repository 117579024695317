import React from 'react';
import { Typography, Link } from '@mui/material';
import ExpandableSection from './ExpandableSection';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';

const translations = {
    pl: {
        title: 'Kontakt',
    },
    en: {
        title: 'Contact',
    },
};

const Contact = ({ language }) => {
    const currentTranslation = translations[language];

    return (
        <ExpandableSection title={currentTranslation.title}>
            <Typography variant="body1" color="textSecondary" sx={{ textAlign: 'center' }}>
                {"e-mail: "}
                <Link href="mailto:filip.mroz12@gmail.com" color="inherit">
                    filip.mroz12@gmail.com
                </Link>
            </Typography>

            <Typography variant="body1" color="textSecondary" sx={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 1 }}>
                <LinkedInIcon sx={{ mr: 1 }} />
                <Link
                    href="https://www.linkedin.com/in/mrozfilip2000/"
                    target="_blank"
                    rel="noopener noreferrer"
                    color="inherit"
                >
                    linkedin.com/in/mrozfilip2000
                </Link>
            </Typography>

            <Typography variant="body1" color="textSecondary" sx={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 1 }}>
                <GitHubIcon sx={{ mr: 1 }} />
                <Link
                    href="https://github.com/CaptainBomPa"
                    target="_blank"
                    rel="noopener noreferrer"
                    color="inherit"
                >
                    github.com/CaptainBomPa
                </Link>
            </Typography>
        </ExpandableSection>
    );
};

export default Contact;
